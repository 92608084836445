var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-detail-view", style: "width: 290px" },
    [
      _c(
        "div",
        {
          staticClass: "detail-title",
          style: "left: " + (_vm.$route.query.action ? 0 : -56) + "px",
        },
        [
          _c("img", {
            staticClass: "icon icon-back",
            attrs: { src: "/img/project/icon-back.png", alt: "" },
            on: { click: _vm.backToList },
          }),
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.type === "add"
                  ? "添加项目"
                  : _vm.type === "edit"
                  ? "编辑项目"
                  : _vm.title
              ) +
              "\n  "
          ),
        ]
      ),
      _c("div", { staticClass: "project-detail-content" }, [
        _vm.type != "add" && _vm.type != "edit"
          ? _c("div", { staticClass: "scroll-box-show" }, [
              _c("div", { staticClass: "name" }, [
                _c("div", { staticClass: "name-left" }, [
                  _vm._m(0),
                  _c("p", { staticClass: "name-title title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(this.flyTimeTotal) +
                        "\n          "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "name-right" }, [
                  _vm._m(1),
                  _c("p", { staticClass: "name-title title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(this.flyDistanceTotalStr) +
                        "\n          "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "name" }, [
                _c("div", { staticClass: "name-left" }, [
                  _vm._m(2),
                  _c("p", { staticClass: "name-title title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(this.taskCompletionRate) +
                        "\n          "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "name-right" }),
              ]),
              _c("div", { staticClass: "equipment" }, [
                _vm._m(3),
                _c(
                  "div",
                  { staticClass: "equipment-content dark-table" },
                  [
                    _c("avue-crud", {
                      attrs: {
                        data: _vm.equipmentData,
                        option: _vm.equipmentOption,
                      },
                      on: {
                        "size-change": _vm.sizeChangeEquipment,
                        "current-change": _vm.currentChangeEquipment,
                      },
                      model: {
                        value: _vm.equipmentPage,
                        callback: function ($$v) {
                          _vm.equipmentPage = $$v
                        },
                        expression: "equipmentPage",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "member" }, [
                _vm._m(4),
                _c(
                  "div",
                  { staticClass: "member-content  dark-table" },
                  [
                    _c("avue-crud", {
                      attrs: { data: _vm.memberData, option: _vm.memberOption },
                      on: {
                        "size-change": _vm.sizeChangeMember,
                        "current-change": _vm.currentChangeMember,
                      },
                      model: {
                        value: _vm.memberPage,
                        callback: function ($$v) {
                          _vm.memberPage = $$v
                        },
                        expression: "memberPage",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _c("div", { staticClass: "scroll-box-edit" }, [
              _c("div", { staticClass: "from-box" }, [
                _c("div", { staticClass: "name" }, [
                  _vm._m(5),
                  _c("div", { staticClass: "name-input" }, [
                    _c(
                      "div",
                      { staticClass: "name-content content" },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入项目名称",
                            maxlength: 20,
                          },
                          model: {
                            value: _vm.detailList.projectName,
                            callback: function ($$v) {
                              _vm.$set(_vm.detailList, "projectName", $$v)
                            },
                            expression: "detailList.projectName",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "name" }, [
                  _vm._m(6),
                  _c("div", { staticClass: "name-input" }, [
                    _c(
                      "div",
                      { staticClass: "name-content content" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择项目类型",
                              loading: _vm.loadingOptions,
                              disabled: _vm.loadingOptions,
                            },
                            model: {
                              value: _vm.detailList.projectType,
                              callback: function ($$v) {
                                _vm.$set(_vm.detailList, "projectType", $$v)
                              },
                              expression: "detailList.projectType",
                            },
                          },
                          _vm._l(_vm.projectTypeOptions, function (option) {
                            return _c("el-option", {
                              key: option.dictKey,
                              attrs: {
                                label: option.dictValue,
                                value: option.dictKey,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "name" }, [
                  _vm._m(7),
                  _c("div", { staticClass: "name-input" }, [
                    _c(
                      "div",
                      { staticClass: "name-content content" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择行业类型",
                              loading: _vm.loadingOptions,
                              disabled: _vm.loadingOptions,
                            },
                            model: {
                              value: _vm.detailList.industryType,
                              callback: function ($$v) {
                                _vm.$set(_vm.detailList, "industryType", $$v)
                              },
                              expression: "detailList.industryType",
                            },
                          },
                          _vm._l(_vm.businessTypeOptions, function (option) {
                            return _c("el-option", {
                              key: option.dictKey,
                              attrs: {
                                label: option.dictValue,
                                value: option.dictKey,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "name" }, [
                  _vm._m(8),
                  _c("div", { staticClass: "name-input" }, [
                    _c(
                      "div",
                      { staticClass: "name-content content" },
                      [
                        _c("select-tree", {
                          attrs: {
                            multiple: true,
                            data: _vm.projectTeamOptions,
                          },
                          model: {
                            value: _vm.detailList.deptIdList,
                            callback: function ($$v) {
                              _vm.$set(_vm.detailList, "deptIdList", $$v)
                            },
                            expression: "detailList.deptIdList",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "name" }, [
                  _vm._m(9),
                  _c("div", { staticClass: "name-input" }, [
                    _c(
                      "div",
                      { staticClass: "name-content content" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择项目负责人" },
                            model: {
                              value: _vm.detailList.projectManagerId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.detailList,
                                  "projectManagerId",
                                  $$v
                                )
                              },
                              expression: "detailList.projectManagerId",
                            },
                          },
                          _vm._l(_vm.projectLeaderOptions, function (option) {
                            return _c("el-option", {
                              key: option.value,
                              attrs: {
                                label: option.label,
                                value: option.value,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "name" }, [
                  _vm._m(10),
                  _c("div", { staticClass: "name-input" }, [
                    _c(
                      "div",
                      { staticClass: "name-content content" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            type: "datetime",
                            placeholder: "选择日期时间",
                          },
                          model: {
                            value: _vm.detailList.startTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.detailList, "startTime", $$v)
                            },
                            expression: "detailList.startTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "name" }, [
                  _vm._m(11),
                  _c("div", { staticClass: "name-input" }, [
                    _c(
                      "div",
                      { staticClass: "name-content content" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            type: "datetime",
                            "default-time": "23:59:59",
                            placeholder: "选择结束时间",
                          },
                          model: {
                            value: _vm.detailList.finishTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.detailList, "finishTime", $$v)
                            },
                            expression: "detailList.finishTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "name" }, [
                  _c(
                    "p",
                    { staticClass: "name-title title" },
                    [
                      _vm._m(12),
                      _vm.type != "add"
                        ? _c(
                            "el-upload",
                            {
                              attrs: {
                                action:
                                  "/api/blade-resource/oss/endpoint/upload-file",
                                headers: {
                                  "Blade-Auth": "bearer " + _vm.token,
                                },
                                "show-file-list": false,
                                "on-success": _vm.handleSuccess,
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#409EFF",
                                    cursor: "pointer",
                                  },
                                },
                                [_vm._v("导入模板")]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "template_list" }, [
                    _c(
                      "div",
                      { staticClass: "template_list_content" },
                      [
                        _vm._m(13),
                        _vm._l(_vm.templatesList, function (item) {
                          return _c(
                            "div",
                            { key: item.id, staticClass: "template_list_item" },
                            [
                              _c("div", { staticClass: "name_value" }, [
                                _vm._v(_vm._s(item.templateName)),
                              ]),
                              _c(
                                "div",
                                { staticClass: "operation_value" },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: item.id },
                                      on: {
                                        input: function ($event) {
                                          return _vm.changeFiring(item)
                                        },
                                      },
                                      model: {
                                        value: _vm.firing,
                                        callback: function ($$v) {
                                          _vm.firing = $$v
                                        },
                                        expression: "firing",
                                      },
                                    },
                                    [_vm._v("启用")]
                                  ),
                                  item.systemFlag != 1
                                    ? _c("i", {
                                        staticClass: "el-icon-delete",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteTemplateFiles(item)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "name" }, [
                  _vm._m(14),
                  _c("div", { staticClass: "name-input" }, [
                    _c(
                      "div",
                      { staticClass: "name-content content" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择飞机", multiple: "" },
                            model: {
                              value: _vm.detailList.uavList,
                              callback: function ($$v) {
                                _vm.$set(_vm.detailList, "uavList", $$v)
                              },
                              expression: "detailList.uavList",
                            },
                          },
                          _vm._l(
                            _vm.projectEqumitUavOptions,
                            function (option) {
                              return _c("el-option", {
                                key: option.id,
                                attrs: {
                                  label: option.equipmentName,
                                  value: option.id,
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "name" }, [
                  _vm._m(15),
                  _c("div", { staticClass: "name-input" }, [
                    _c(
                      "div",
                      { staticClass: "name-content content" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择载荷", multiple: "" },
                            model: {
                              value: _vm.detailList.podList,
                              callback: function ($$v) {
                                _vm.$set(_vm.detailList, "podList", $$v)
                              },
                              expression: "detailList.podList",
                            },
                          },
                          _vm._l(
                            _vm.projectEqumitLoadOptions,
                            function (option) {
                              return _c("el-option", {
                                key: option.id,
                                attrs: {
                                  label: option.equipmentName,
                                  value: option.id,
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "name" }, [
                  _vm._m(16),
                  _c("div", { staticClass: "name-input" }, [
                    _c(
                      "div",
                      { staticClass: "name-content content" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择数据链",
                              multiple: "",
                            },
                            model: {
                              value: _vm.detailList.linkList,
                              callback: function ($$v) {
                                _vm.$set(_vm.detailList, "linkList", $$v)
                              },
                              expression: "detailList.linkList",
                            },
                          },
                          _vm._l(
                            _vm.projectEqumitDatalikeOptions,
                            function (option) {
                              return _c("el-option", {
                                key: option.id,
                                attrs: {
                                  label: option.equipmentName,
                                  value: option.id,
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "name" }, [
                  _vm._m(17),
                  _c("div", { staticClass: "name-input" }, [
                    _c(
                      "div",
                      { staticClass: "name-content content" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择项目成员",
                              multiple: "",
                            },
                            model: {
                              value: _vm.detailList.userIdList,
                              callback: function ($$v) {
                                _vm.$set(_vm.detailList, "userIdList", $$v)
                              },
                              expression: "detailList.userIdList",
                            },
                          },
                          _vm._l(_vm.projectLeaderOptions, function (option) {
                            return _c("el-option", {
                              key: option.value,
                              attrs: {
                                label: option.label,
                                value: option.value,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "center" }, [
                  _c("p", { staticClass: "center-title title" }, [
                    _vm._v("项目中心点"),
                  ]),
                  _c(
                    "p",
                    {
                      staticClass: "center-add",
                      on: { click: _vm.setProjectPoint },
                    },
                    [
                      _c("img", {
                        staticClass: "icon-location icon",
                        attrs: {
                          src: "/img/project/icon-location2.png",
                          alt: "",
                        },
                      }),
                      _vm._v("设置项目中心点\n          "),
                    ]
                  ),
                  _c("p", { staticClass: "center-detail" }, [
                    _vm._v(
                      "\n            经纬度： " +
                        _vm._s(
                          _vm.getPoint(_vm.projectPoint) ||
                            _vm.getBackPoint(_vm.detailList)
                        ) +
                        "\n          "
                    ),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "finish" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "finish-btn",
                      attrs: { loading: _vm.saveLoading, type: "primary" },
                      on: { click: _vm.handleFinish },
                    },
                    [_vm._v("\n          保存\n        ")]
                  ),
                ],
                1
              ),
            ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogType === "role" ? "添加人员" : "添加设备",
            visible: _vm.dialogTableVisible,
            "append-to-body": "",
            "custom-class": "role-dialog",
            width: "617px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "search" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchItem,
                  expression: "searchItem",
                },
              ],
              staticClass: "search-input",
              attrs: {
                type: "text",
                placeholder:
                  _vm.dialogType === "role" ? "搜索人员名称" : "搜索设备名称",
              },
              domProps: { value: _vm.searchItem },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchItem = $event.target.value
                },
              },
            }),
            _c("img", {
              staticClass: "search-btn",
              attrs: { src: "/img/project/icon-search.png", alt: "" },
              on: { click: _vm.searchValue },
            }),
          ]),
          _c("avue-crud", {
            attrs: {
              data: _vm.roleEquipList,
              option: _vm.option,
              page: _vm.page,
              beforeOpen: _vm.onLoad,
            },
            on: {
              "selection-change": _vm.selectionChange,
              "current-change": _vm.currentChange,
              "size-change": _vm.sizeChange,
            },
          }),
          _c("div", { staticClass: "btn-group" }, [
            _c(
              "button",
              {
                staticClass: "btn-group-cancer",
                on: {
                  click: function ($event) {
                    _vm.dialogTableVisible = false
                  },
                },
              },
              [_vm._v("\n        取消\n      ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn-group-submit",
                on: { click: _vm.submitRoleOrEquips },
              },
              [_vm._v("\n        确定\n      ")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title title" }, [
      _c("span", [_vm._v("累计飞行时长:")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title title" }, [
      _c("span", [_vm._v("累计飞行里程:")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title title" }, [
      _c("span", [_vm._v("完成任务/任务总数:")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "equipment-title title" }, [
      _c("span", [_vm._v("项目设备:")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "member-title title" }, [
      _c("span", [_vm._v("项目成员:")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title title" }, [
      _c("span", [
        _c("a", { staticClass: "required" }, [_vm._v("*")]),
        _vm._v("项目名称"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title title" }, [
      _c("span", [
        _c("a", { staticClass: "required" }, [_vm._v("*")]),
        _vm._v("项目类型"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title title" }, [
      _c("span", [
        _c("a", { staticClass: "required" }, [_vm._v("*")]),
        _vm._v("行业类型"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title title" }, [
      _c("span", [
        _c("a", { staticClass: "required" }, [_vm._v("*")]),
        _vm._v("项目所属团体"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title title" }, [
      _c("span", [
        _c("a", { staticClass: "required" }, [_vm._v("*")]),
        _vm._v("项目负责人"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title title" }, [
      _c("span", [
        _c("a", { staticClass: "required" }, [_vm._v("*")]),
        _vm._v("开始时间"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title title" }, [
      _c("span", [
        _c("a", { staticClass: "required" }, [_vm._v("*")]),
        _vm._v("结束时间"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("a", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("巡检报告模板"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "template_list_header" }, [
      _c("div", { staticClass: "name" }, [_vm._v("名称")]),
      _c("div", { staticClass: "operation" }, [_vm._v("操作")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title title" }, [
      _c("span", { staticClass: "r" }, [_vm._v("使用飞机")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title title" }, [
      _c("span", [_vm._v("使用载荷")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title title" }, [
      _c("span", [_vm._v("使用数据链")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title title" }, [
      _c("span", [
        _c("a", { staticClass: "required" }, [_vm._v("*")]),
        _vm._v("项目成员"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }