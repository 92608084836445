<template>
  <div class="project-detail-view" :style="`width: 290px`">
    <div
      :style="`left: ${$route.query.action? 0: -56}px`"
      class="detail-title">
      <img
        class="icon icon-back"
        @click="backToList"
        src="/img/project/icon-back.png"
        alt=""
      />
      {{ type === "add"  ? "添加项目" : type ==="edit" ? "编辑项目" : title }}
    </div>
    <div class="project-detail-content">
      <!-- 修改项目 -->
      <div v-if="type != 'add' && type != 'edit'" class="scroll-box-show">
        <div class="name">
          <div class="name-left">
            <p class="name-title title">
              <span>累计飞行时长:</span>
            </p>
            <p class="name-title title">
              {{ this.flyTimeTotal }}
            </p>
          </div>
          <div class="name-right">
            <p class="name-title title">
              <span>累计飞行里程:</span>
            </p>
            <p class="name-title title">
              {{ this.flyDistanceTotalStr }}
            </p>
          </div>
        </div>
        <div class="name">
          <div class="name-left">
            <p class="name-title title">
              <span>完成任务/任务总数:</span>
            </p>
            <p class="name-title title">
              {{ this.taskCompletionRate }}
            </p>
          </div>
          <div class="name-right">
            <!-- <p class="name-title title">
              <span>任务完成率:</span>
            </p>
            <p class="name-title title">
              {{ this.taskCompletionRateStr }}%
            </p> -->
          </div>
        </div>
        <div class="equipment">
          <div class="equipment-title title">
            <span>项目设备:</span>
          </div>
          <div class="equipment-content dark-table">
            <avue-crud :data="equipmentData" :option="equipmentOption" v-model:page="equipmentPage"
              @size-change="sizeChangeEquipment" @current-change="currentChangeEquipment"></avue-crud>
          </div>
        </div>
        <div class="member">
          <div class="member-title title">
            <span>项目成员:</span>
          </div>
          <div class="member-content  dark-table">
            <avue-crud :data="memberData" :option="memberOption" v-model:page="memberPage" @size-change="sizeChangeMember"
              @current-change="currentChangeMember"></avue-crud>
          </div>
        </div>
      </div>
      <!-- 新增项目 -->
      <div v-else class="scroll-box-edit">
        <div class="from-box">
          <div class="name">
            <p class="name-title title">
              <span><a class="required">*</a>项目名称</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <el-input v-model="detailList.projectName" 
                  placeholder="请输入项目名称" :maxlength="20"></el-input>
              </div>
            </div>
          </div>
          <div class="name">
            <p class="name-title title">
              <span><a class="required">*</a>项目类型</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <el-select v-model="detailList.projectType" placeholder="请选择项目类型" 
                  :loading="loadingOptions" :disabled="loadingOptions">
                  <el-option v-for="option in projectTypeOptions" :key="option.dictKey" :label="option.dictValue"
                    :value="option.dictKey"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="name">
            <p class="name-title title">
              <span><a class="required">*</a>行业类型</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <el-select v-model="detailList.industryType" placeholder="请选择行业类型" 
                  :loading="loadingOptions" :disabled="loadingOptions">
                  <el-option v-for="option in businessTypeOptions" :key="option.dictKey" :label="option.dictValue"
                    :value="option.dictKey"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="name">
            <p class="name-title title">
              <span><a class="required">*</a>项目所属团体</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <select-tree
                  :multiple="true"
                  :data="projectTeamOptions"
                  v-model="detailList.deptIdList"/>
              </div>
            </div>
          </div>
          <div class="name">
            <p class="name-title title">
              <span><a class="required">*</a>项目负责人</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <el-select v-model="detailList.projectManagerId" placeholder="请选择项目负责人">
                  <el-option v-for="option in projectLeaderOptions" :key="option.value" :label="option.label"
                    :value="option.value"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="name">
            <p class="name-title title">
              <span><a class="required">*</a>开始时间</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="detailList.startTime" type="datetime"
                  placeholder="选择日期时间">
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="name">
            <p class="name-title title">
              <span><a class="required">*</a>结束时间</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <el-date-picker v-model="detailList.finishTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                  :default-time="'23:59:59'" placeholder="选择结束时间">
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="name">
            <p class="name-title title">
              <span><a class="required">*</a>巡检报告模板</span>
              <el-upload v-if="type != 'add'" action="/api/blade-resource/oss/endpoint/upload-file" 
                :headers="{'Blade-Auth': `bearer ${token}`}" :show-file-list="false" :on-success="handleSuccess">
                <span style="color: #409EFF;cursor: pointer;">导入模板</span>
              </el-upload>
            </p>
            <div class="template_list">
              <div class="template_list_content">
                <div class="template_list_header">
                  <div class="name">名称</div>
                  <div class="operation">操作</div>
                </div>
                <div class="template_list_item" v-for="item in templatesList" :key="item.id">
                  <div class="name_value">{{item.templateName}}</div>
                  <div class="operation_value">
                    <el-radio v-model="firing" :label="item.id" @input="changeFiring(item)">启用</el-radio>
                    <i class="el-icon-delete" v-if="item.systemFlag != 1" @click="deleteTemplateFiles(item)"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="name">
            <p class="name-title title">
              <span class="r">使用飞机</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <el-select v-model="detailList.uavList" placeholder="请选择飞机" multiple>
                  <el-option v-for="option in projectEqumitUavOptions" :key="option.id" :label="option.equipmentName"
                    :value="option.id"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="name">
            <p class="name-title title">
              <span>使用载荷</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <el-select v-model="detailList.podList" placeholder="请选择载荷" multiple>
                  <el-option v-for="option in projectEqumitLoadOptions" :key="option.id" :label="option.equipmentName"
                    :value="option.id"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="name">
            <p class="name-title title">
              <span>使用数据链</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <el-select v-model="detailList.linkList" placeholder="请选择数据链" multiple>
                  <el-option v-for="option in projectEqumitDatalikeOptions" :key="option.id" :label="option.equipmentName"
                    :value="option.id"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="name">
            <p class="name-title title">
              <span><a class="required">*</a>项目成员</span>
            </p>
            <div class="name-input">
              <div class="name-content content">
                <el-select v-model="detailList.userIdList" placeholder="请选择项目成员" multiple>
                  <el-option v-for="option in projectLeaderOptions" :key="option.value" :label="option.label"
                    :value="option.value"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="center">
            <p class="center-title title">项目中心点</p>
            <p class="center-add" @click="setProjectPoint">
              <img class="icon-location icon" src="/img/project/icon-location2.png" alt="" />设置项目中心点
            </p>
            <p class="center-detail">
              经纬度： {{ getPoint(projectPoint) || getBackPoint(detailList) }}
            </p>
          </div>
        </div>
        <div class="finish">
          <el-button
            :loading="saveLoading"
            class="finish-btn"
            type="primary"
            @click="handleFinish">
            保存
          </el-button>
        </div>
      </div>
    </div>
    <el-dialog :title="dialogType === 'role' ? '添加人员' : '添加设备'" :visible.sync="dialogTableVisible" append-to-body
      custom-class="role-dialog" :width="'617px'">
      <div class="search">
        <input class="search-input" type="text" v-model="searchItem"
          :placeholder="dialogType === 'role' ? '搜索人员名称' : '搜索设备名称'" />
        <img class="search-btn" @click="searchValue" src="/img/project/icon-search.png" alt="" />
      </div>
      <avue-crud :data="roleEquipList" :option="option" :page="page" :beforeOpen="onLoad"
        @selection-change="selectionChange" @current-change="currentChange" @size-change="sizeChange">
      </avue-crud>
      <div class="btn-group">
        <button class="btn-group-cancer" @click="dialogTableVisible = false">
          取消
        </button>
        <button class="btn-group-submit" @click="submitRoleOrEquips">
          确定
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDictList,
  getTemplates,
  updateTemplate,
  addTemplate,
  removeTemplate,
  getRoleList,
  getEquipList,
  unbindEquip,
  unbindMember,
  bindMember,
  bindEquip,
  addProject,
  modifyProject,
  getProjectTypeOptions,
  getProjectTeamOptions,
  getProjectDetail ,
  getUserNew,
  getUseEquipmentList,
  getProjectEqumentPage,
  getProjectMemberPage
} from "@/api/project/index";
import { getToken } from "@/util/auth";
import { getDictionary } from '@/api/system/dictbiz'
import { getList, getUser } from '@/api/system/dept'
import { mapGetters } from "vuex";

export default {
  name: "ProjectDetail",
  data() {
    return {
      projectId: '',
      detailInfo: {},
      type: 'detail',
      flyDistanceUnit: 0,
      dialogTableVisible: false,
      newProjectTeam: null,
      projectDesp: "",
      projectName: "",
      dialogTitle: "添加设备",
      dialogType: "",
      equipDic: [],
      roleOptions: [],
      projectLeaderOptions: [],
      projectEqumitUavOptions: [],
      projectEqumitLoadOptions: [],
      projectEqumitDatalikeOptions: [],
      memberData: [],
      searchItem: "",
      projectDetail: {},
      flyTimeTotal: "",
      flyDistanceTotalStr: "",
      taskCompletionRate: "0/0",
      taskCompletionRateStr: "0",
      option: {
        delBtn: false,
        editBtn: false,
        addBtn: false,
        border: true,
        selection: true,
        refreshBtn: false,
        columnBtn: false,
        actions: false,
        showOperate: false,

        headerAlign: "center",
        align: "center",
        menuTitle: "",
        menu: false,
        column: [
          {
            label: "用户名",
            prop: "realName",
            align: "center",
            search: true,
          },
          {
            label: "账户名",
            prop: "account",
            overHidden: true,
          },
          {
            label: "角色",
            prop: "account",
            overHidden: true,
          },
          {
            label: "手机",
            prop: "account",
            overHidden: true,
          }
        ],
      },
      roleEquipList: [],
      detailList: {
        detail: "",
        projectName: "",
        equipmentConfigList: [],
        userList: [],
        deptIdList: []
      },
      selectionList: [],
      saveLoading: false,
      managerInfo: {},
      centerPoint: "",
      projectTeamOptions: [],
      projectTypeOptions: [], // 项目类型选项列表
      businessTypeOptions: [], // 行业类型选项列表
      firing: '',
      templatesList: [], // 巡检报告模板
      token: getToken(), // 上传报告携带
      loadingOptions: false, // 是否正在加载选项列表
      equipmentPage: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: false,
        pageSize: 10
      },
      memberPage: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: false,
        pageSize: 10
      },
      equipmentData: [],
      equipmentOption: {
        align: 'center',
        menuAlign: 'center',
        delBtn: false,
        editBtn: false,
        addBtn: false,
        refreshBtn: false,
        actions: false,
        menu: false,
        selection: false,
        header: false,
        width: 50,
        pagination: true,
        prevBtn: true,
        nextBtn: true,
        column: [
          {
            label: '设备名称',
            prop: 'equipmentName'
          },
          {
            label: '设备类型',
            prop: 'equipmentType'
          }
        ]
      },
      memberOption: {
        align: 'center',
        menuAlign: 'center',
        delBtn: false,
        editBtn: false,
        addBtn: false,
        refreshBtn: false,
        actions: false,
        menu: false,
        selection: false,
        header: false,
        width: 50,
        pagination: true,
        prevBtn: true,
        nextBtn: true,
        column: [
          {
            label: '姓名',
            prop: 'realName'
          },
          {
            label: '角色名',
            prop: 'roleName'
          }
        ]
      }
    };
  },
  watch: {
    detailInfo: {
      handler(n) {
        this.detailList = n;
      },
      immediate: true,
      deep: true,
    },

    'detailList.startTime':{
      handler (newStartTime){
        if (this.detailList.endTime && this.detailList.endTime <= newStartTime) {
          this.detailList.endTime = null;
        }
      },
      immediate: true,
      deep: true
    },

    'detailList.endTime':{
      handler(newEndTime) {
        if (this.detailList.startTime && newEndTime <= this.detailList.startTime) {
          this.detailList.endTime = null;
        }
      },
      immediate: true,
      deep: true
    },

    'detailList.projectTaskSumResultVO.flyTimeTotal': {
      handler(newVal) {
        if (!newVal || newVal === null) {
          this.flyTimeTotal = "00:00";
          return;
        }
        var hours = Math.floor(newVal / 3600);
        var minutes = Math.floor((newVal - (hours * 3600)) / 60);
        var remainingSeconds = newVal - (hours * 3600) - (minutes * 60);

        if (hours < 10) {
          hours = "0" + hours;
        }

        if (minutes < 10) {
          minutes = "0" + minutes;
        }

        if (remainingSeconds < 10) {
          remainingSeconds = "0" + remainingSeconds;
        }

        this.flyTimeTotal = hours + "h" + minutes + "m" + remainingSeconds + "s";
      },
      immediate: true,
      deep: true
    },

    'detailList.projectTaskSumResultVO.flyDistanceTotal':{
      handler(newVal) {
        if (!newVal) {
          this.flyDistanceTotalStr = "0km";
          return;
        }
        // if (newVal >= 1000) {
        //   // 大于等于 1000 米，将距离转换为千米
        //   this.flyDistanceTotalStr = (newVal / 1000).toFixed(2) + "km";
        // } else {
        //   // 小于 1000 米，直接显示米数
        //   this.flyDistanceTotalStr = newVal + "m";
        // }

        this.flyDistanceTotalStr = (newVal / 1000).toFixed(2) + "km";
      },
      immediate: true,
      deep: true
    },

    'detailList.projectTaskSumResultVO.taskCount': {
      handler(newVal) {
        this.calculateTaskRate();
      },
      immediate: true,
      deep: true
    },

    'detailList.projectTaskSumResultVO.completeTaskCount': {
      handler(newVal) {
        this.calculateTaskRate();
      },
      immediate: true,
      deep: true
    },

    'detailList.projectTaskSumResultVO.taskCount':{
      handler(newVal) {
        this.calculateTaskRate();
      },
      immediate: true,
      deep: true
    },

    'detailList.projectTaskSumResultVO.completeTaskCount':{
      handler(newVal) {
        this.calculateTaskRate();
      },
      immediate: true,
      deep: true
    },

    'detailList.deptIdList': {
      handler(newProjectTeams) {
        if (!newProjectTeams || !Array.isArray(newProjectTeams)) {
          return
        }
        //获取无人机
        getUseEquipmentList({ deptIdList: newProjectTeams, equipmentType: 'uav' }).then((res) => {
          if (res.data.code === 200) {
            const data = res.data.data;
            this.projectEqumitUavOptions = data;
          }
        });
        //获取载荷设备
        getUseEquipmentList({ deptIdList: newProjectTeams, equipmentType: 'pod' }).then((res) => {
          if (res.data.code === 200) {
            const data = res.data.data;
            this.projectEqumitLoadOptions = data;
          }
        });
        // 数据链
        getUseEquipmentList({ deptIdList: newProjectTeams, equipmentType: 'datalink' }).then((res) => {
          if (res.data.code === 200) {
            const data = res.data.data;
            this.projectEqumitDatalikeOptions = data;
          }
        });
      },
      immediate: true,
      deep: true
    },

    'detailList.industryType': {
      handler(val) {
        this.firing = '';
        this.templatesList = [];
        if (val) {
          if (this.type !== 'add') {
            this.getTemplatesData();
          } else {
            this.templatesList = [{id: 'tymb', templateName: '默认模板', status: 1, systemFlag: 1}];
            this.firing = 'tymb';
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    // 角色字典映射
    roleZH() {
      return function (val) {
        let role = "";
        this.roleOptions.map((item) => {
          if (val === item.id) {
            role = item.roleName;
          }
        });
        return role;
      };
    },
    // 装备字典映射
    equipZH() {
      return function (val) {
        let equip = "";
        this.equipDic.map((item) => {
          if (val === item.dictKey) {
            equip = item.dictValue;
          }
        });
        return equip;
      };
    },
    // 中心点展示
    getPoint() {
      return function (arr) {
        let point = "";
        if (arr && arr.length) {
          const lon = parseFloat(arr[0]);
          const lat = parseFloat(arr[1]);
          if (lon >= 0) {
            point += lon.toFixed(7) + " E,";
          } else {
            point += lon.toFixed(7) + " W,";
          }
          if (lat >= 0) {
            point += lat.toFixed(7) + " N";
          } else {
            point += lat.toFixed(7) + "S";
          }
        }
        this.detailList.centerPoint = point;
        return point;
      };
    },
    getBackPoint() {
      return function (data) {
        let point = "";
        if (data && data.centerPointLon && data.centerPointLat) {
          if (data.centerPointLon >= 0) {
            point += data.centerPointLon.substring(0, 9) + " E,";
          } else {
            point += data.centerPointLon.substring(0, 9) + " W,";
          }
          if (data.centerPointLat >= 0) {
            point += data.centerPointLat.substring(0, 9) + " N";
          } else {
            point += data.centerPointLat.substring(0, 9) + "S";
          }
        }
        return point;
      };
    },
    title() {
      if (this.$route.query &&  this.$route.query.projectName) {
        return this.$route.query.projectName
      }
      if (this.detailInfo && this.detailInfo.projectName) {
        return this.detailInfo.projectName
      }
      return '' 
    },
    ...mapGetters(["projectPoint"]),
  },
  created() {
    this.projectId = this.$route.query.projectId
    this.type = this.$route.query.action? this.$route.query.action: 'detail'
    this.getProjectTypeOptions();
    this.getBusinessTypeOptions();
    this.getProjectTeamOptions();
    this.getManagerlist();
    if (this.type !== 'add' && this.projectId) {
      this.getProjectEqumentPage(this.equipmentPage);
      this.getProjectMemberPage(this.memberPage);
      getProjectDetail({id: this.projectId}).then((res) => {
        if (res.data.code === 200) {
          this.detailInfo = res.data.data;
        }
      });
    }
  },
  mounted() {
    this.$EventBus.$off('mission-center-project-edit')
    this.$EventBus.$on('mission-center-project-edit', ()=>{
      this.type = 'edit'
    })
  },
  methods: {
    backToList() {
      this.$router.push('/mission-center/item-manager-config');
    },
    calculateTaskRate() {
      if (!this.detailList.projectTaskSumResultVO) {
        return
      }
      // if (!this.detailList.projectTaskSumResultVO.taskCount || !this.detailList.projectTaskSumResultVO.completeTaskCount) {
      //   this.taskCompletionRate = "0" + "/" + "0";
      //   this.taskCompletionRateStr = 0;
      // } else {
      //   this.taskCompletionRate = this.detailList.projectTaskSumResultVO.completeTaskCount + "/" + this.detailList.projectTaskSumResultVO.taskCount;
      //   this.taskCompletionRateStr = (this.detailList.projectTaskSumResultVO.completeTaskCount / this.detailList.projectTaskSumResultVO.taskCount).toFixed(4) * 100;
      // }

        this.taskCompletionRate = this.detailList.projectTaskSumResultVO.completeTaskCount + "/" + this.detailList.projectTaskSumResultVO.taskCount;
        this.taskCompletionRateStr = (this.detailList.projectTaskSumResultVO.completeTaskCount / this.detailList.projectTaskSumResultVO.taskCount).toFixed(4) * 100;
    },
    // 添加成员或设备搜索
    searchValue() {
      this.page.current = 1;
      if (this.dialogType === "role") {
        this.getUser();
      } else {
        this.getEquip();
      }
    },
    // 获取成员列表
    getUser() {
      const params = {
        ...this.page,
        userName: this.searchItem,
        deptId: this.newProjectTeam
      };
      getUserNew(params).then((res) => {
        if (res.data.code === 200) {
          this.page.total = res.data.data.total;
          this.roleEquipList = res.data.data;
          this.roleEquipList.map((item) => {
            if (!item && item.role) {
              item.role = null;
            }
            if (!item && item.roleId) {
              item.roleId = null;
            }
          });
        }
      });
    },
    // 获取设备列表
    getEquip() {
      const params = {
        isSearchProjectEquipment: false,
        ...this.page,
        equipmentName: this.searchItem,
      };
      getEquipList(params).then((res) => {
        if (res.data.code === 200) {
          this.page.total = res.data.data.total;
          this.roleEquipList = res.data.data.records;
        }
      });
    },
    // 获取角色下拉选项
    getRole() {
      getRoleList().then((res) => {
        if (res.data.code === 200) {
          this.roleOptions = res.data.data;
        }
      });
    },
    // 获取设备字典
    getDicData() {
      const params = {
        code: "uav_equipment_type",
      };
      getDictList(params).then((res) => {
        if (res.data.code === 200) {
          this.equipDic = res.data.data;
        }
      });
    },
    // 弹窗初始化
    roleDialogShow(type) {
      this.searchItem = "";
      this.page.current = 1;
      this.dialogType = type;
      if (type === "role") {
        this.dialogTitle = "添加角色";
        this.option.menu = true;
        this.option.column = [
          {
            label: "用户名",
            prop: "realName",
            align: "center",
          },
          {
            label: "账户名",
            prop: "account",
            overHidden: true,
          },
        ];
        this.getUser();


      } else {
        this.dialogTitle = "添加设备";
        this.option.menu = false;
        this.option.column = [
          {
            label: "设备名称",
            prop: "equipmentName",
            align: "center",
          },
          {
            label: "设备类型",
            prop: "equipmentType",
            overHidden: true,
          },
        ];
        this.getEquip();
      }
      this.dialogTableVisible = true;
    },
    // 设置中心点
    setProjectPoint() {
      this.$store.dispatch("setHandlerType", { 
        type: "projectPoint",
        params: this.detailInfo
      });
    },
    // 添加弹窗提交(绑定角色或设备)
    submitRoleOrEquips() {
      if (this.type === "add") {
        if (this.dialogType === "role") {
          this.selectionList.map((item) => {
            let canAdd = true;
            if (this.detailList.userList.length) {
              this.detailList.userList.map((subItem) => {
                if (subItem.id === item.id) {
                  canAdd = false;
                  return;
                }
              });
              canAdd && this.detailList.userList.push(item);
            } else {
              this.detailList.userList.push(item);
            }
          });
        } else {
          this.selectionList.map((item) => {
            let canAdd = true;
            if (this.detailList.equipmentConfigList.length) {
              this.detailList.equipmentConfigList.map((subItem) => {
                if (subItem.id === item.id) {
                  canAdd = false;
                  return;
                }
              });
              canAdd && this.detailList.equipmentConfigList.push(item);
            } else {
              this.detailList.equipmentConfigList.push(item);
            }
          });
        }
        this.dialogTableVisible = false;
      } else {
        if (this.dialogType === "role") {
          let itemAuthorityConfig = [];
          this.selectionList.map((item) => {
            itemAuthorityConfig.push({
              roleId: item.roleId,
              userId: item.id,
              projectId: this.detailList.id,
            });
          });
          bindMember(itemAuthorityConfig)
            .then((res) => {
              if (res.data.code === 200) {
                this.selectionList.map((item) => {
                  let canAdd = true;
                  if (this.detailList.userList.length) {
                    this.detailList.userList.map((subItem) => {
                      if (
                        subItem.userId === item.id ||
                        subItem.id === item.id
                      ) {
                        canAdd = false;
                        return;
                      }
                    });
                    canAdd && this.detailList.userList.push(item);
                  } else {
                    this.detailList.userList.push(item);
                  }
                });
                this.$message.success("绑定人员成功");
                this.dialogTableVisible = false;
              }
            })
            .catch((err) => {
              this.$message.error(err.msg);
            });
        } else {
          let equipmentIds = "";
          this.selectionList.map((item) => {
            equipmentIds += item.id + ",";
          });
          equipmentIds = equipmentIds.substring(0, equipmentIds.length - 1);
          const data = {
            equipmentIds,
            projectId: this.detailList.id,
          };
          bindEquip(data)
            .then((res) => {
              if (res.data.code === 200) {
                this.selectionList.map((item) => {
                  let canAdd = true;
                  if (this.detailList.equipmentConfigList.length) {
                    this.detailList.equipmentConfigList.map((subItem) => {
                      if (subItem.id === item.id) {
                        canAdd = false;
                        return;
                      }
                    });
                    canAdd && this.detailList.equipmentConfigList.push(item);
                  } else {
                    this.detailList.equipmentConfigList.push(item);
                  }
                });
                this.$message.success("绑定设备成功");
                this.dialogTableVisible = false;
              }
            })
            .catch((err) => {
              this.$message.error(err.msg);
            });
        }
      }
    },
    // 解绑设备
    delEquipRow(item, index) {
      this.$confirm("是否确认删除该设备", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.type === "add") {
            this.detailList.equipmentConfigList.splice(index, 1);
          } else {
            const data = {
              equipmentIds: item.id,
              projectId: this.detailList.id,
            };
            unbindEquip(data).then((res) => {
              if (res.data.code === 200) {
                this.detailList.equipmentConfigList.splice(index, 1);
                this.$message.success(res.data.msg);
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 解绑角色
    delRoleRow(item, index) {
      this.$confirm("是否确认删除该成员", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.type === "add") {
            if (
              this.detailList.userList[index].userId !== this.managerInfo.userId
            ) {
              this.detailList.userList.splice(index, 1);
            } else {
              this.$message.warning("项目创建人不可删除！");
            }
          } else {
            const data = [
              {
                roleId: item.roleId,
                userId: item.userId,
                projectId: this.detailList.id,
              },
            ];
            unbindMember(data).then((res) => {
              if (res.data.code === 200) {
                this.detailList.userList.splice(index, 1);
                this.$message.success(res.data.msg);
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 
    selectionChange(list) {
      this.selectionList = list;
    },
    //
    currentChange(current) {
      this.page.current = current;
      const data = {
        current: this.page.current,
      };
      this.$nextTick(() => {
        if (this.dialogType === "role") {
          this.getUser(data);

        } else {
          this.getEquip(data);
        }
      });
    },
    sizeChange(size) {
      this.page.size = size;
    },
    onLoad() {
      this.roleDialogShow();
    },
    getProjectTypeOptions() {
      this.loadingOptions = true;
      // 发起远程请求，获取项目类型选项列表
      // 假设您有一个名为 getProjectTypeOptions 的远程请求方法
      getProjectTypeOptions().then((res) => {
        this.projectTypeOptions = res.data.data; // 将远程获取的项目类型选项列表赋值给 projectTypeOptions
        this.loadingOptions = false; // 停止加载状态
      });

    },
    // 获取行业类型
    getBusinessTypeOptions() {
      this.loadingOptions = true;
      getDictionary({code: 'BusinessType'}).then(res=>{
        if (res.data.code === 200) {
          this.businessTypeOptions = res.data.data || [];
        }
      }).finally(() => {
        this.loadingOptions = false;
      });
    },
    // 获取巡检报告模板
    getTemplatesData(type) {
      getTemplates({projectId: this.projectId, industryType: this.detailList.industryType}).then(res=>{
        if (res.data.code === 200) {
          if (res.data.data) {
            let firingItem = res.data.data.find(t => t.status === 1);
            if (firingItem) this.firing = firingItem.id;
            this.templatesList = res.data.data || []
            if (type) {
              let systemFlagItem = this.templatesList.find(t => t.systemFlag == 1);
              if (!systemFlagItem) systemFlagItem = this.templatesList[0];
              this.firing = systemFlagItem.id;
              this.changeFiring(systemFlagItem);
            }
          } else {
            this.templatesList = [];
          }
        }
      });
    },
    // 上传巡检报告模板文件成功回调
    handleSuccess(response) {
      this.handleTemplateFiles(response);
    },
    // 选择巡检报告模板
    handleTemplateFiles(response) {
      addTemplate({
        industryType: this.detailList.industryType,
        projectId: this.projectId,
        templateFileUrl: response.data.link,
        templateName: response.data.originalName
      }).then(res => {
        if (res.data.code === 200) {
          this.getTemplatesData();
        }
      });
    },
    // 删除巡检报告模板
    deleteTemplateFiles(node) {
      removeTemplate({ids: node.id}).then(res => {
        if (res.data.code == 200) {
          let isCs = node.id == this.firing ? 'chse' : ''
          this.getTemplatesData(isCs);
        }
      });
    },
    getProjectEqumentPage(page) {
      getProjectEqumentPage(
        {
          current: page.currentPage,
          size: page.pageSize,
          projectId: this.projectId
        }
      ).then((res) => {
        const data = res.data.data;
        this.equipmentPage.total = data.total;
        this.equipmentData = data.records;
      });

    },
    getProjectMemberPage(page) {
      getProjectMemberPage(
        {
          current: page.currentPage,
          size: page.pageSize,
          id: this.projectId
        }
      ).then((res) => {
        const data = res.data.data;
        this.memberPage.total = data.userVOList ? data.userVOList.length : 0;
        this.memberData = data.userVOList || [];
      });

    },
    getProjectTeamOptions() {
      this.loadingOptions = true;
      // 发起远程请求，获取项目类型选项列表
      // 假设您有一个名为 getProjectTypeOptions 的s远程请求方法
      getProjectTeamOptions().then((res) => {
        this.projectTeamOptions = this.filterListData(res.data.data)
        // res.data.data.map(

        // ); 
        // 将远程获取的项目类型选项列表赋值给 projectTypeOptions
        this.loadingOptions = false; // 停止加载状态
      });
    },
    filterListData(arr) {
      let res = []
      for (var i = 0; i < arr.length; i++) {
        let item = arr[i]
        res.push(
            {
              label: item.title,
              value: item.value,
              children: (item.children && Array.isArray(item.children) && item.children.length > 0)? 
                this.filterListData(item.children): undefined
            }
        )
      }
      return res
    },
    // 改变firing巡检
    changeFiring(node) {
      updateTemplate({projectId: this.projectId, id: node.id}).then(()=>{});
    },
    // 保存
    handleFinish() {
      this.saveLoading = true
      if (this.type === "add") {
        addProject(this.detailList).then((res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
            this.$router.push(this.$route.matched[1].path);
            this.$store.dispatch("setProjectPoint", null);
            this.$store.dispatch("setRouteVisible", false);
          }
        }).catch((err) => {
          this.$message.error(err.msg);
        }).finally(()=>{
          this.saveLoading = false;
        })
      } else {
        // todo 这种清空方式，是不是可以以后使用aos-select-tree代替？？ 
        if(this.detailList.uavList === null){
          this.detailList.uavList=[];
        }
        if(this.detailList.linkList === null){
          this.detailList.linkList=[];
        }
        if(this.detailList.podList === null){
          this.detailList.podList=[];
        }
        modifyProject(this.detailList).then((res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
            this.$router.push(this.$route.matched[1].path);
            this.$store.dispatch("setProjectPoint", null);
            this.$store.dispatch("setRouteVisible", false);
          }
        }).catch((err) => {
          this.$message.error(err.msg);
        }).finally(()=>{
          this.saveLoading = false;
        })
      }
    },
    getList() {
      this.pageee.total = 40
      if (this.pageee.currentPage === 1) {
        this.equipmentData = [
          {
            id: 1,
            name: '张三',
            sex: '男'
          }, {
            id: 2,
            name: '李四',
            sex: '女'
          }
        ]
      } else if (this.pageee.currentPage == 2) {
        this.equipmentData = [
          {
            id: 3,
            name: '王五',
            sex: '女'
          }, {
            id: 4,
            name: '赵六',
            sex: '女'
          }
        ]
      } if (this.pageee.currentPage === 1) {
        this.equipmentData = [
          {
            id: 1,
            name: '张三',
            sex: '男'
          }, {
            id: 2,
            name: '李四',
            sex: '女'
          }
        ]
      } else if (this.pageee.currentPage == 2) {
        this.equipmentData = [
          {
            id: 3,
            name: '王五',
            sex: '女'
          }, {
            id: 4,
            name: '赵六',
            sex: '女'
          }
        ]
      }
    },
    sizeChangeEquipment(val) {
      this.equipmentPage.currentPage = 1
      this.equipmentPage.pageSize = val
      this.getList()
      this.$message.success('行数' + val)
    },
    currentChangeEquipment(val) {
      this.equipmentPage.currentPage = val
      this.getProjectEqumentPage(this.equipmentPage)
      this.$message.success('页码' + val)
    },
    sizeChangeMember(val) {
      this.memberPage.currentPage = 1
      this.memberPage.pageSize = val
      this.getProjectMemberPage(this.memberPage)
      this.$message.success('行数' + val)
    },
    currentChangeMember(val) {
      this.memberPage.currentPage = val
      this.getList()
      this.$message.success('页码' + val)
    },
    getManagerlist() {
      getUser().then((res) => {
        if (res.data.code === 200) {
          const data = res.data.data;
          let arr = data.map((item) => {
            return {
              label: item.realName,
              value: item.id
            }
          });
          this.projectLeaderOptions = arr
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll-box-show {
  overflow: auto;
  height: 100%;
  .name {
    display: flex;
  }

  .name-left,
  .name-right {
    flex: 1;
  }

  .name-title {
    margin: 5px;
    size: 14px;
  }

}

::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #000000;
}

::v-deep .el-input__inner {
  width: 100%;
  border: none !important;
  background: #000000;
  border-radius: 2px;
  padding: 0 10px;
  border: 1px solid #666666;
  font-size: 14px;
  color: #ffffff;
}

::v-deep .el-select {
  width: 100%;
}

::v-deep .el-date-editor {
  width: 100%;
}

::v-deep .role-dialog,
.custom-dialog {
  background: rgba(15, 18, 25, 1);

  .search {
    width: 100%;
    margin-bottom: 45px;
    position: relative;

    &-input {
      width: 100%;
      box-sizing: border-box;
      height: 32px;
      border-radius: 3px;
      border: 1px solid rgba(255, 255, 255, 0.29);
      background: transparent;
      padding-left: 16px;
      color: #ffffff;
    }

    &-btn {
      position: absolute;
      right: 10px;
      top: 8px;
      height: 16px;
      cursor: pointer;
    }
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    background: #265edd;
  }

  .el-checkbox__input .el-checkbox__inner {
    background: transparent;
  }


  .choose-role {
    color: #ffffff;
    font-size: 14px;
    margin-top: 24px;
    margin-bottom: 7px;

    &--red {
      padding-right: 7px;
    }
  }

  .el-dialog__header {
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.29);

    .el-dialog__title {
      color: #ffffff;
    }
  }

  .el-table__fixed-right::before,
  .el-table__fixed::before {
    content: none;
  }

  .el-dialog__body {
    padding: 38px 76px 61px 87px;

    .avue-crud__tip {
      display: none;
    }

    .el-collapse-item__wrap {
      background: rgba(15, 18, 25, 1);
      border: none;

      .el-form-item__label {
        color: #ffffff;
      }
    }

    .avue-crud {
      .avue-crud__menu {
        background: rgba(15, 18, 25, 1);
      }

      .avue-form {
        .el-collapse {
          border: none;
        }
      }

      .el-form,
      .el-table {
        background: rgba(15, 18, 25, 1);

        .cell,
        tr>th,
        tr>td {
          color: #ffffff;
          background: rgba(15, 18, 25, 1);
        }
      }

      .avue-crud__menu {
        display: none;
      }
    }

    .el-table--border {
      // border: 1px solid rgba(151, 151, 151, 0.29) !important;
      border-top: 1px solid rgba(151, 151, 151, 0.29) !important;
      border-left: 1px solid rgba(151, 151, 151, 0.29) !important;
    }

    .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
      // background-color: transparent;
      content: none;
    }

    .el-card__body {
      background: rgba(15, 18, 25, 1);

      .el-table td,
      .el-table th.is-leaf {
        border-bottom: 1px solid rgba(151, 151, 151, 0.29) !important;
        border-right: 1px solid rgba(151, 151, 151, 0.29) !important;
      }
    }
  }

  .uav-select {
    width: 100%;

    .el-input {
      width: 100%;

      input {
        width: 100%;
      }
    }
  }

  .btn-group {
    margin-top: 36px;
    text-align: right;

    &-cancer {
      width: 79px;
      height: 32px;
      border-radius: 2px;
      border: 1px solid #4d4d4d;
      font-size: 14px;
      font-family: MicrosoftYaHeiUI;
      color: #ffffff;
      background: transparent;
      margin-right: 23px;
    }

    &-submit {
      width: 83px;
      height: 36px;
      background: #265edd;
      border-radius: 4px;
      font-size: 14px;
      font-family: MicrosoftYaHeiUI;
      color: #ffffff;
    }
  }
}

.project-detail-view {
  position: relative;
  height: 100%;
  width: 100%;
  .detail-title {
    position: absolute;
    top: -49px;
    font-size: 14px;
    font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
    font-weight: bold;
    color: #ffffff;
    padding-top: 9px;
    padding-bottom: 11px;
    display: flex;
    align-items: center;
    .icon-back {
      margin-right: 4px;
    }
  }
  .scroll-box-edit {
    height: calc(100%);
    .from-box{
      overflow: auto;
      height: calc(100% - 59px);
      .name-input {
        margin: 0 17px;
      }
    }
  }

  .name {
    &-title {
      padding: 10px 5px 5px 17px;

      &-id {
        color: #76787d;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }

  .description {
    &-title {
      padding: 5px 11px 5px 17px;
    }
  }

  .title {
    font-size: 12px;
    font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
    font-weight: bold;
    color: #ffffff;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
  }

  .content {
    background: #030303;
    font-size: 14px;
    font-family: MicrosoftYaHeiUI;
    color: #ffffff;
    line-height: 18px;
  }

  .list-content {
    &-item {
      display: flex;
      justify-content: space-between;

      &:last-child {
        border-bottom: 1px solid #000000;
      }

      &--st {
        padding: 13px 10px 16px 21px;
        border-top: 1px solid #000000;
        display: flex;
        align-items: center;
      }

      &--nd {
        padding: 13px 10px 16px 17px;
        border: 1px solid #000000;
        border-bottom: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &--rd {
        padding: 13px 10px 16px 24px;
        border-top: 1px solid #000000;
        display: flex;
        align-items: center;
        text-align: center;
      }

      span {
        width: 33%;
        font-size: 12px;
        font-family: MicrosoftYaHeiUI;
        color: #ffffff;
      }
    }
  }

  .member {
    &-title {
      padding: 10px 19px 10px 17px;

      &-add {
        font-size: 12px;
        font-family: MicrosoftYaHeiUI;
        color: #4981ff;
        cursor: pointer;
      }
    }
  }

  .equipment {
    &-title {
      padding: 10px 19px 10px 17px;

      &-add {
        font-size: 12px;
        font-family: MicrosoftYaHeiUI;
        color: #4981ff;
        cursor: pointer;
      }
    }
  }

  .center {
    border-bottom: 1px solid #030303;

    &-title {
      padding: 15px 0 7px 17px;
    }

    &-add {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0px 0 9px 17px;
      font-size: 12px;
      font-family: MicrosoftYaHeiUI;
      color: #4981ff;
    }

    &-detail {
      padding: 0 0 24px 17px;
      font-size: 12px;
      font-family: MicrosoftYaHeiUI;
      color: #999999;
    }
  }

  .finish {
    // margin-top: 15px;
    // margin-left: 10px

    &-btn {
      width: 267px;
      height: 36px;
      line-height: 36px;
      background: #265edd;
      border-radius: 3px;
      font-size: 14px;
      font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
      font-weight: bold;
      color: #ffffff;
      padding: 0;
      border: none;
      margin-top: 10px;
      margin-left: 10px;
    }
  }

  .icon {
    cursor: pointer;

    &-edit {
      height: 13px;
    }

    &-delete {
      margin-left: 18px;
      height: 13px;
    }

    &-add {
      margin-left: 4px;
      height: 10px;
    }

    &-location {
      height: 18px;
      margin-right: 4px;
    }
  }

  .template_list {
    display: flex;
    flex-direction: column;
    padding: 5px 17px 0 17px;
    color: #ffffff;
    font-size: 14px;
    .template_list_content {
      width: 100%;
      background: #000000;
      .template_list_header {
        display: flex;
        background: #40434A;
        padding: 5px 0;
        border-radius: 3px;
        .name {
          flex: 1;
          text-indent: 10px;
        }
        .operation {
          width: 85px;
          text-indent: 10px;
          margin-left: 10px;
        }
      }
      .template_list_item {
        display: flex;
        padding-top: 13px;
        &:last-child {
          padding-bottom: 13px;
        }
        .name_value {
          flex: 1;
          padding: 0 8px;
        }
        .operation_value {
          width: 85px;
          margin-left: 2px;
          .el-radio {
            color: #ffffff;
            margin-right: 10px;
          }
          .el-icon-delete {
            cursor: pointer;
          }
        }
      }
    }
  }

  ::v-deep .avue-crud .el-table__row:hover {
    color: #000000;
  }
}

.project-detail-content {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
}

::v-deep .uav-input .el-input__inner,
::v-deep .uav-input .el-textarea__inner {
  border: none !important;
  background: #000000;
  border-radius: 2px;
  padding: 0 10px;
  border: 1px solid #666666;
  font-size: 14px;
  color: #ffffff;
}

::v-deep .el-pagination__sizes,
::v-deep .el-pagination__jump {
  display: none !important;
}

::v-deep .el-pagination__total {
  color: #ffffff;
}

::v-deep .btn-prev,
::v-deep .btn-next {
  background: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.29);
  color: #ffffff;

  i {
    color: #ffffff;
  }
}

::v-deep .el-pagination.is-background .el-pager li {
  background: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.29);

  &.number {
    background: transparent !important;
    color: #ffffff;
  }

  &.active {
    background: #265edd !important;
    border: none;
  }
}

::v-deep .uav-select-popper {
  &.el-select-dropdown {
    border: none;
  }

  .el-select-dropdown__wrap {
    background: #000000;
  }

  .el-select-dropdown__item {
    color: #ffffff;

    &:hover,
    &.hover {
      background: #265edd;
    }

    // display: flex;
    // justify-content: center;
  }

  .popper__arrow {
    border-top-color: #000000;
    border-bottom-color: #000000;

    &::after {
      border-top-color: #000000;
      border-bottom-color: #000000;
    }
  }
}

.list-content {
  background-color: #40434a;
}

.list-content-item {
  display: flex;
  justify-content: space-between;
}

.list-content-item--st {
  flex: 1;
}

.required {
  color: red;
  margin-right: 5px;
}
</style>
